import { BiMap } from "react-icons/bi";
import { FiMail, FiPhone } from "react-icons/fi";

const ContactInfo = () => {
  return (
    <div className="grid grid-cols-1 gap-6 py-16 sm:grid-cols-2 md:grid-cols-3">
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
          <a href="tel:+905323163540" className="text-white">
            <FiPhone />
          </a>
        </div>
        <h1 className="mt-2 text-lg font-semibold">Telefon Numaralarımız</h1>
        <p>0362 435 75 21</p>
        <p>0532 316 35 40</p>
      </div>
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
          <a href="mailto:birlikinsaatsamsun@gmail.com" className="text-white">
            <FiMail />
          </a>
        </div>
        <h1 className="mt-2 text-lg font-semibold">Email Adreslerimiz</h1>
        <p>birlikinsaatsamsun@gmail.com</p>
      </div>
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
          <a
            href="https://www.google.com/maps/place/Birlik+Insaat/@41.2934661,36.3319863,19.5z/data=!4m6!3m5!1s0x408877b8d7278d27:0xccb0c425a89ecfb3!8m2!3d41.2935224!4d36.3316413!16s%2Fg%2F1hc580tcg?entry=ttu&g_ep=EgoyMDI1MDExNS4wIKXMDSoASAFQAw%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <BiMap />
          </a>
        </div>
        <h1 className="mt-2 text-lg font-semibold">İşyeri Adresimiz</h1>
        <p>Kale Mahallesi Sakarya Caddesi</p>
        <p>No:59 Samsun/İlkadım</p>
      </div>
    </div>
  );
};

export default ContactInfo;