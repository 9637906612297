import { FiCheck, FiPhone, FiUsers } from "react-icons/fi";

const Speciality = () => {
  return (
    <div className="pt-10 pb-16">
      <div className="flex flex-wrap gap-10">
        <div className="flex-1 basis-[20rem]">
          <h1 className="sub-heading">BİZ KİMİZ ?</h1>
          <h1 className="heading">Çeyrek asrı aşan tecrübemizle yanınızdayız...</h1>
          <p className="mt-3">
            Şirketimizin temel prensibi, kaliteyi hesaplı olarak tüketiciye
            sunmak ve sektörde uzun vadeli hizmet vermeyi başarmaktır.
          </p>
          <div className="mt-4">
            <div className="flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p>Faizsiz, Kefilsiz 24 Ay Sabit Ödeme Garantisi</p>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p>Hisseli Satış ile Geleceğe Yatırım Fırsatı</p>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p>Aracınızı Tam Değerinde Ödeme Yöntemi Olarak Kabul Ediyoruz</p>
            </div>
            <div className="mt-2 flex-align-center gap-x-2">
              <div className="icon-box text-primary !bg-primary/20">
                <FiCheck />
              </div>
              <p>Bugüne Kadar Binlerce Müşterimizi Konut ve İşyeri Sahibi Yaptık</p>
            </div>
            <button 
              className="mt-4 btn btn-primary" 
              onClick={() => window.location.href = 'tel:+905323163540'}
            >
              İLETİŞİM: 0362 435 75 21 - 0532 316 35 40
            </button>
          </div>
        </div>
        <div className="flex-1 basis-[20rem]">
          <div className="relative">
            <img
              src="/images/birlik30.jpeg"
              alt=""
              className="rounded-lg w-full sm:h-[400px] object-cover"
            />
            <div className="absolute -bottom-10 sm:bottom-5 -left-2 md:-left-20">
              <div className="p-3 bg-white rounded-lg shadow-md w-72 flex-center-between gap-x-3 dark:bg-dark-light">
                <h1>Binlerce müşterimizi faizsiz ev ve işyeri sahibi yaptık.</h1>
                <div className="icon-box text-primary !bg-primary/20">
                  <FiUsers />
                </div>
              </div>
              <div className="p-3 mt-4 ml-8 bg-white rounded-lg shadow-md w-72 flex-center-between gap-x-3 dark:bg-dark-light">
                <h1>
                  Telefon numaralarımızdan bizimle iletişim kurabilirsiniz.
                </h1>
                <div className="icon-box text-primary !bg-primary/20">
                  <FiPhone />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speciality;
