import logo from '../../logo.png';
import { FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { FiFacebook } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="text-slate-200 -mt-20 md:-mt-4">
      <footer className="px-4 md:px-0 py-8 -mt-16">
        <div className="flex flex-wrap gap-8 md:gap-2 justify-center md:justify-between">
          <div className="flex-1 basis-[18rem] md:basis-[10rem] text-center md:text-left">
            <Link to="/" className="flex-shrink-0 flex-align-center gap-x-1 justify-center md:justify-start">
              <div className="flex-shrink-0 flex-align-center">
                <img
                  src={logo}
                  alt="Logo"
                  className="h-20 max-h-full mx-auto md:mx-0"
                  style={{ maxWidth: '200px', height: 'auto' }}
                />
              </div>
            </Link>
            <div className="mt-4 md:-mt-12">
              <p className="text-sm -mt-12 md:mt-0">
                Çeyrek asırdan fazla olan tecrübemizle yanınızdayız. Bizi ofisimizde ziyaret edebilirsiniz.
              </p>
              <div className="gap-3 my-4 md:my-6 flex-center-center justify-center md:justify-start">
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-box bg-dark-light hover:bg-hover-color-dark"
                >
                  <FiFacebook />
                </a>
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-box bg-dark-light hover:bg-hover-color-dark"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://www.instagram.com/birlikinsaat.samsun"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-box bg-dark-light hover:bg-hover-color-dark"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/birlikinsaat/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-box bg-dark-light hover:bg-hover-color-dark"
                >
                  <FaLinkedin />
                </a>
              </div>
            </div>
          </div>

          <div className="flex-1 basis-[18rem] md:basis-[10rem] text-center md:text-left">
            <h2 className="text-lg font-semibold mt-0 md:mt-16">Hizmetlerimiz</h2>
            <ul>
              <li className="my-2 md:my-3 text-muted">
                <a href="/">24 Ay Vadeli Satış</a>
              </li>
              <li className="my-2 md:my-3 text-muted">
                <a href="/">Değerinde Araç Alımı</a>
              </li>
              <li className="my-2 md:my-3 text-muted">
                <a href="/">Hisseli Satış Geleceğe Yatırım</a>
              </li>
            </ul>
          </div>

          <div className="flex-1 basis-[18rem] md:basis-[10rem] text-center md:text-left">
            <h2 className="text-lg font-semibold mt-0 md:mt-16">Hızlı Linkler</h2>
            <ul>
              <li className="my-2 md:my-3 text-muted">
                <a href="/">Ana Sayfa</a>
              </li>
              <li className="my-2 md:my-3 text-muted">
                <a href="/property">Projelerimiz</a>
              </li>
              <li className="my-2 md:my-3 text-muted">
                <a href="/about-2">Hakkında</a>
              </li>
              <li className="my-2 md:my-3 text-muted">
                <a href="/services">Hizmetler</a>
              </li>
              <li className="my-2 md:my-3 text-muted">
                <a href="/contact">İletişim</a>
              </li>
            </ul>
          </div>

          <div className="flex-1 basis-[18rem] md:basis-[10rem] text-center md:text-left">
            <h2 className="text-lg font-semibold mt-0 md:mt-16">Blog Yazıları</h2>
            <ul>
              <li className="my-2 md:my-3 text-muted">
                <a href="/">Çok Yakında</a>
              </li>
            </ul>
          </div>

          <div className="flex-1 basis-[18rem] md:basis-[10rem] text-center md:text-left">
            <h2 className="text-lg font-semibold mt-0 md:mt-16">İletişim</h2>
            <p className="text-sm text-muted mt-4 md:mt-0">
              Bizimle aşağıdaki numaralar ve mail adresleri üzerinden iletişim kurabilirsiniz.
            </p>
            <div className="justify-center my-3 flex-align-center">
              <button className="btn btn-primary -ml-20">0362 435 75 21</button>
            </div>
            <div className="justify-center my-3 flex-align-center">
              <button className="btn btn-primary ml-4">0532 316 35 40</button>
            </div>
          </div>
        </div>
      </footer>
      <div className="py-4 mt-0 text-center border-t text-muted border-dark">
        <p>
          Web Tasarım: <a href="https://www.linkedin.com/in/mehmet-ali-yayla-835b55273/" target="_blank" rel="noopener noreferrer" className="text-primary">Mehmet Ali Yayla</a> | © 2024
          <br /> Tüm Hakları Saklıdır.
        </p>
      </div>
    </div>
  );
};

export default Footer;