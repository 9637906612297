import {
  BiShieldAlt2,
  BiFootball,
  BiLandscape,
} from "react-icons/bi";
import {
  FaCar,
  FaWalking,
  FaTree,
} from "react-icons/fa";
import {
  GiKidSlide,
  GiParkBench,
} from "react-icons/gi";
import {
  MdElectricCar
} from "react-icons/md";


export const services = [
  {
    id: 1,
    name: "Sosyal Yaşam Alanları",
    icon: <GiParkBench />,
  },
  {
    id: 2,
    name: "Kapalı Otopark",
    icon: <FaCar />,
  },
  {
    id: 3,
    name: "7/24 Güvenlik",
    icon: <BiShieldAlt2 />,
  },
  {
    id: 4,
    name: "Çocuk Oyun Parkı",
    icon: <GiKidSlide />,
  },
  {
    id: 5,
    name: "Spor Alanları",
    icon: <BiFootball />,
  },
  {
    id: 6,
    name: "Elektrikli Şarj İstasyonu",
    icon: <MdElectricCar />,
  },
  {
    id: 7,
    name: "Yürüyüş Alanları",
    icon: <FaWalking />,
  },
  {
    id: 8,
    name: "Özel Peyzaj Uygulamaları",
    icon: <FaTree />,
  },
  {
    id: 9,
    name: "Eşsiz Manzara",
    icon: <BiLandscape />,
  },
];

export const projects = [
  {
    id: 1,
    name: "Vefa Lojmanları",
    image: "/images/birlik10.jpeg",
  },
  {
    id: 2,
    name: "Vefa Konutları",
    image: "/images/birlik30.jpeg",
  },
  {
    id: 3,
    name: "Nilüfer Evleri",
    image: "/images/property (21).jpg",
  },
  {
    id: 4,
    name: "Safran Evleri-2",
    image: "/images/property (1).jpg",
  },
  {
    id: 5,
    name: "Berceste Sitesi",
    image: "/images/property (20).jpg",
  },
  {
    id: 6,
    name: "Gümüşkent",
    image: "/images/property (18).jpg",
  },
  {
    id: 7,
    name: "Safran Evleri-1",
    image: "/images/property (17).jpg",
  },
  {
    id: 8,
    name: "Gümüşkent 3.Etap",
    image: "/images/birlik5.jpeg",
  },
  {
    id: 9,
    name: "Açı Okulları - Bafra",
    image: "/images/birlik6.jpg",
  },
  {
    id: 12,
    name: "Kaanlar Fabrika",
    image: "/images/g3.png",
  },
  {
    id: 13,
    name: "Birlik Plaza",
    image: "/images/g4.jpeg",
  },
  {
    id: 10,
    name: "",
    image: "/images/g1.jpeg",
  },
];

export const categories = [
  {
    id: 1,
    image: "/images/img1.jpeg",
  },
  {
    id: 2,
    image: "/images/img2.jpeg",
  },
  {
    id: 3,
    image: "/images/img3.jpeg",
  },
  {
    id: 4,
    image: "/images/img4.jpeg",
  },
  {
    id: 5,
    image: "/images/img5.jpeg",
  },
  {
    id: 6,
    image: "/images/img6.jpeg",
  },
];